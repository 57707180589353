import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";

import SweetAlert from "react-bootstrap-sweetalert";
import { updateVehicle } from "../../../utils/services/vehicles";

import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function ModalEditVehicle(props) {
  const { isOpen, toggle, data } = props;
  const [isLoading, setIsloading] = useState(false);

  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dataForm, setDataForm] = useState({});

  const [erroForm, setErroForm] = useState({});

  const [description, setDescription] = useState(data.description);
  const [editLatLonOrigem, setEditLatLonOrigem] = useState(false);
  const [editLatLonDestino, setEditLatLonDestino] = useState(false);
  const [departureLongitude, setDepartureLongitude] = useState(
    data.departureLongitude
  );
  const [departureLatitude, setDepartureLatitude] = useState(
    data.departureLatitude
  );
  const [arrivalLongitude, setArrivalLongitude] = useState(
    data.arrivalLongitude
  );
  const [arrivalLatitude, setArrivalLatitude] = useState(data.arrivalLatitude);
  const [departureDatetime, setDepartureDatetime] = useState(
    data.departureDatetime.split("T")[0]
  );
  const [arrivalDatetime, setArrivalDatetime] = useState(
    data.arrivalDatetime?.split("T")[0]
  );
  const [departureTime, setDepartureTime] = useState(
    data.departureDatetime.split("T")[1].substring(0, 5)
  );
  const [arrivalTime, setArrivalTime] = useState(
    data.arrivalDatetime?.split("T")[1].substring(0, 5)
  );
  const [driverName, setDriverName] = useState(data.driverName);
  const [vehicleType, setVehicleType] = useState(data.vehicleType);
  const [seatingCapacity, setSeatingCapacity] = useState(data.seatingCapacity);
  const [company, setCompany] = useState(data.company);
  const [meetingPoint, setMeetingPoint] = useState(data.meetingPoint);
  const [phone, setPhone] = useState(data.phone);
  const [notes, setNotes] = useState(data.notes);
  const [optionLocalizationOrigem, setOptionLocalizationOrigem] = useState(
    data.origin
  );
  const [optionLocalizationDestino, setOptionLocalizationDestino] = useState(
    data.destination
  );
  const [localizationSelected, setLocalizationSelected] = useState();

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function verifyErros() {
    if (!description) {
      setErroForm({ name: "O campo Nome é obrigatório" });
      return;
    } else setErroForm({});

    if (
      typeof optionLocalizationOrigem === "object" ||
      optionLocalizationOrigem.length === 0
    ) {
      setErroForm({ origin: "O campo Origem é obrigatório" });
      return;
    } else setErroForm({});

    if (
      typeof optionLocalizationDestino === "object" ||
      optionLocalizationDestino.length === 0
    ) {
      setErroForm({ destination: "O campo Destino é obrigatório" });
      return;
    } else setErroForm({});

    if (!departureDatetime) {
      setErroForm({ departureDatetime: "O campo Data da Saída é obrigatório" });
      return;
    } else setErroForm({});

    if (!departureTime) {
      setErroForm({ departureTime: "O campo Hora da Saída é obrigatório" });
      return;
    } else setErroForm({});

    if (!seatingCapacity || seatingCapacity < 1) {
      setErroForm({
        seatingCapacity: "O campo Capacidade do veículo é obrigatório",
      });
      return;
    } else setErroForm({});

    submitForm();
  }
  function localizationOrigem(e) {
    setLocalizationSelected("");
    axios
      .get(
        `https://us-central1-inteegra-ping.cloudfunctions.net/GoogleAutoComplete?search=${e}&language=pt`
      )
      .then((localizations) => {
        setOptionLocalizationOrigem(localizations);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function localizationDestino(e) {
    axios
      .get(
        `https://us-central1-inteegra-ping.cloudfunctions.net/GoogleAutoComplete?search=${e}&language=pt`
      )
      .then((localizations) => {
        setOptionLocalizationDestino(localizations);
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  function selectedLocale(type, l) {
    if (type === "origem") {
      document.getElementById("localInputOrigem").value = l.description;
      setOptionLocalizationOrigem(l.description);
      setDepartureLatitude(null);
      setDepartureLongitude(null);
    }
    if (type === "destino") {
      document.getElementById("localInputDestino").value = l.description;
      setOptionLocalizationDestino(l.description);
      setArrivalLatitude(null);
      setArrivalLongitude(null);
    }
  }

  function submitForm() {
    var dataForm = {
      eventId: props.idEvent,
      description,
      origin: optionLocalizationOrigem,
      destination: optionLocalizationDestino,
      departureLongitude: parseFloat(departureLongitude) ?? null,
      departureLatitude: parseFloat(departureLatitude) ?? null,
      arrivalLongitude: parseFloat(arrivalLongitude) ?? null,
      arrivalLatitude: parseFloat(arrivalLatitude) ?? null,
      departureDatetime:
        departureDatetime && departureTime
          ? `${departureDatetime}T${departureTime}`
          : null,
      arrivalDatetime:
        arrivalDatetime && arrivalTime
          ? `${arrivalDatetime}T${arrivalTime}`
          : null,
      driverName,
      vehicleType,
      seatingCapacity: parseInt(seatingCapacity),
      company,
      meetingPoint,
      phone,
      notes,
    };

    setIsloading(true);
    updateVehicle(dataForm, data.id)
      .then((res) => {
        setIsloading(false);
        console.log(res.message);
        if (res.message === "updated") {
          setsuccess_dlg(true);
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("erro", error);
      });
  }

  return (
    <>
      {" "}
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggleBackdrop();
        }}
        autoFocus={true}
        centered={true}
        size="lg"
      >
        {success_dlg ? (
          <SweetAlert
            success
            title={"Editado"}
            onConfirm={() => {
              setsuccess_dlg(false);
              toggle();
              setTimeout(() => {
                props.getAllVehicles();
              }, 1000);
            }}
          >
            {"Veículo editado com sucesso!"}
          </SweetAlert>
        ) : null}
        <ModalHeader toggle={toggle}>Editar Veículo</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <div>
                <label className="form-label">ID</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.id}
                  disabled={true}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Nome *</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.name}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Origem *</label>
                <input
                  defaultValue={data.origin}
                  className="form-control"
                  type="text"
                  id="localInputOrigem"
                  // onChange={(e) => setOrigin(e.target.value)}
                  onChange={(e) => {
                    localizationOrigem(e.target.value);
                    setDepartureLatitude("");
                    setDepartureLongitude("");
                  }}
                />
                {erroForm.origin && (
                  <p style={{ color: "red" }}>{erroForm.origin}</p>
                )}
              </div>
              {optionLocalizationOrigem.data &&
              optionLocalizationOrigem.data.success ? (
                <ul className="localization">
                  {optionLocalizationOrigem.data.success.predictions.map(
                    (l) => {
                      return (
                        <li
                          key={l.description}
                          className="localizationLi"
                          onClick={() => selectedLocale("origem", l)}
                        >
                          {l.description}
                        </li>
                      );
                    }
                  )}
                </ul>
              ) : null}
            </Col>

            <Col md={12}>
              <div
                className="form-check form-switch"
                style={{
                  borderTop: "1px solid #dbdbdb",
                  borderBottom: "1px solid #dbdbdb",
                  marginTop: 5,
                  marginBottom: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`checkLatLonOrigem`}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEditLatLonOrigem(true);
                    } else setEditLatLonOrigem(false);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={`checkLatLonOrigem`}
                >
                  Editar Latitude/Longitude de Origem
                </label>
              </div>
            </Col>
          </Row>
          {editLatLonOrigem && (
            <Row>
              <Col md={6}>
                <div>
                  <label className="form-label">Latitude de origem </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={data.departureLatitude}
                    value={departureLatitude}
                    onChange={(e) => setDepartureLatitude(e.target.value)}
                  />
                  {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.origin}</p>
                )} */}
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label className="form-label">Longitude de origem</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={data.departureLongitude}
                    value={departureLongitude}
                    onChange={(e) => setDepartureLongitude(e.target.value)}
                  />
                  {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.name}</p>
                )} */}
                </div>
              </Col>{" "}
            </Row>
          )}
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Destino *</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.destination}
                  // onChange={(e) => setDestination(e.target.value)}
                  id="localInputDestino"
                  onChange={(e) => {
                    localizationDestino(e.target.value);
                    setArrivalLatitude("");
                    setArrivalLongitude("");
                  }}
                />
                {erroForm.destination && (
                  <p style={{ color: "red" }}>{erroForm.destination}</p>
                )}
              </div>
              {optionLocalizationDestino.data &&
              optionLocalizationDestino.data.success ? (
                <ul className="localization">
                  {optionLocalizationDestino.data.success.predictions.map(
                    (l) => {
                      return (
                        <li
                          key={l.description}
                          className="localizationLi"
                          onClick={() => selectedLocale("destino", l)}
                        >
                          {l.description}
                        </li>
                      );
                    }
                  )}
                </ul>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div
                className="form-check form-switch"
                style={{
                  borderTop: "1px solid #dbdbdb",
                  borderBottom: "1px solid #dbdbdb",
                  marginTop: 5,
                  marginBottom: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`checkLatLonDestino`}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEditLatLonDestino(true);
                    } else setEditLatLonDestino(false);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={`checkLatLonDestino`}
                >
                  Editar Latitude/Longitude de Destino
                </label>
              </div>
            </Col>
          </Row>
          {editLatLonDestino && (
            <Row style={{ marginTop: "10px" }}>
              <Col md={6}>
                <div>
                  <label className="form-label">Latitude de destino </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={data.arrivalLatitude}
                    value={arrivalLatitude}
                    onChange={(e) => setArrivalLatitude(e.target.value)}
                  />
                  {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.origin}</p>
                )} */}
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label className="form-label">Longitude de destino</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={data.arrivalLongitude}
                    value={arrivalLongitude}
                    onChange={(e) => setArrivalLongitude(e.target.value)}
                  />
                  {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.name}</p>
                )} */}
                </div>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: "10px" }}>
            <Col md={6}>
              <div>
                <label className="form-label">Data da Saída *</label>
                <input
                  className="form-control"
                  onChange={(e) => setDepartureDatetime(e.target.value)}
                  defaultValue={data.departureDatetime.split("T")[0]}
                  type="date"
                  id="example-datetime-local-input"
                />
                {erroForm.departureDatetime && (
                  <p style={{ color: "red" }}>{erroForm.departureDatetime}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label">Hora da saída *</label>
                <input
                  className="form-control"
                  type="time"
                  id="example-datetime-local-input"
                  defaultValue={departureTime}
                  onChange={(e) => setDepartureTime(e.target.value)}
                />
                {erroForm.departureTime && (
                  <p style={{ color: "red" }}>{erroForm.departureTime}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={6}>
              <div>
                <label className="form-label">Data da Chegada </label>
                <input
                  className="form-control"
                  onChange={(e) => setArrivalDatetime(e.target.value)}
                  defaultValue={
                    arrivalDatetime.includes("1969") ? "" : arrivalDatetime
                  }
                  type="date"
                  id="example-datetime-local-input"
                />
                {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.origin}</p>
                )} */}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label">Hora da chegada</label>
                <input
                  className="form-control"
                  type="time"
                  id="example-datetime-local-input"
                  defaultValue={
                    arrivalDatetime.includes("1969") ? "" : arrivalTime
                  }
                  onChange={(e) => setArrivalTime(e.target.value)}
                />
                {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.name}</p>
                )} */}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Nome do motorista</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.driverName}
                  onChange={(e) => setDriverName(e.target.value)}
                />
                {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.name}</p>
                )} */}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Tipo de veículo</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.vehicleType}
                  onChange={(e) => setVehicleType(e.target.value)}
                />
                {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.name}</p>
                )} */}
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Capacidade do veículo *</label>
                <input
                  className="form-control"
                  type="number"
                  defaultValue={data.seatingCapacity}
                  onChange={(e) => setSeatingCapacity(e.target.value)}
                />
                {erroForm.seatingCapacity && (
                  <p style={{ color: "red" }}>{erroForm.seatingCapacity}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Ponto de encontro</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.meetingPoint}
                  onChange={(e) => setMeetingPoint(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Telefone</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Empresa</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div>
                <label className="form-label">Observações</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={data.notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
                {/* {erroForm.name && (
                  <p style={{ color: "red" }}>{erroForm.name}</p>
                )} */}
              </div>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              onClick={verifyErros}
            />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalEditVehicle);
