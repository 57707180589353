import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import readXlsxFile from "read-excel-file";
import ModeloUpload from "../../../assets/statics/modelo_quartos.xlsx";
import { formatBytes } from "../../../utils/helpers/files/formatBytes";
// validação
import {
  allExcelColumns,
  findRowsWithError,
  verifyDuplicity,
  verifyUser,
  verifyRooms,
  excelColumnsPtBr,
} from "./importValidation";
// service
import { createRoom } from "../../../utils/services/match";
import { getParticipants } from "../../../utils/services/participants";
// modal
import ModalExcelRows from "./MoalExcelRows";

export default function ModalImportRoom(props) {
  const { isOpen, toggle, eventId, token, stockRooms, updateTables } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [imgError, setImgError] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);
  const importActivated = useRef(false);

  // modal de erros no arquivo xlsx
  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState([]);

  let timerID = null;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  useEffect(() => {
    setselectedFiles([]);
    setImgError("");
    // console.log(stockRooms);
  }, [isOpen]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
    clearTimeout(timerID);
  }

  function cancelImport() {
    importActivated.current = false;
    setselectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  async function uploadService() {
    importActivated.current = true;
    clearTimeout(timerID);
    setBtnSend(false);
    setBtnCancel(true);

    const schema = {
      Hospede1: {
        prop: "host_one",
        type: String,
      },
      Hospede2: {
        prop: "host_two",
        type: String,
      },
      Hospede3: {
        prop: "host_three",
        type: String,
      },
      Hospede4: {
        prop: "host_four",
        type: String,
      },
    };

    let count = 1;
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    const readXlsx = async () => {
      const { rows } = await readXlsxFile(selectedFiles[0], { schema });
      const validateRows = rows.map((row, i) => allExcelColumns(row, i));

      const rowsWithError = findRowsWithError(validateRows);

      setTotalRows(validateRows.length);

      if (rowsWithError.length > 0 || rows.length === 0) {
        setErrors({
          title: "default",
          data: rowsWithError,
        });
        setModalErrors(true);
        return;
      }

      getAllParticipants(validateRows);
    };

    readXlsx();

    async function getAllParticipants(validateRows) {
      const data = {
        idEvent: eventId,
        offset: 1,
        limit: 1000,
        search: "",
        columnSearch: "login",
        status: "ATIVO",
      };

      var formData = await getParticipants(data);
      if (formData.data.count > 0) {
        const users = formData.data.data;

        const validUser = await verifyUser(validateRows, users);

        if (validUser.length > 0) {
          setErrors({
            title: "userNotFound",
            data: validUser,
          });
          setModalErrors(true);
          return;
        }

        const validDuplicity = await verifyDuplicity(validateRows);

        if (validDuplicity.length > 0) {
          setErrors({
            title: "userDuplicate",
            data: validDuplicity,
          });
          setModalErrors(true);
          return;
        }

        const validRooms = await verifyRooms(validateRows, stockRooms);

        if (validRooms.length > 0) {
          setErrors({
            title: "limitRoom",
            data: validRooms,
          });
          setModalErrors(true);
          return;
        }

        validateRows.forEach(async (row, i) => {
          await sleep(100);
          importRoomsService(row, i, validateRows.length, users);
        });
      }
    }

    async function generateChoosenList(roommates, roomSize, users) {
      // console.log("func", roommates, roomSize, allUser);
      const choosenList = [];

      for (let i = 1; i <= roomSize; i++) {
        const roommate = roommates[i - 1];
        const user = users.find((e) => e.email === roommate);

        choosenList.push({
          email: user.email,
          id: user.id,
          name: user.nome,
          order: i,
          status: "completed",
          type: "chooser",
        });
      }

      return choosenList;
    }

    async function getFormatedUser(row) {
      return [
        row.host_one != "Vazio" ? row.host_one : null,
        row.host_two != "Vazio" ? row.host_two : null,
        row.host_three != "Vazio" ? row.host_three : null,
        row.host_four != "Vazio" ? row.host_four : null,
      ].filter((e) => e != null);
    }

    async function importRoomsService(row, index, totalRows, users) {
      var room = await getFormatedUser(row);

      const roomSize = room.length;
      const choosenList = await generateChoosenList(room, roomSize, users);

      const payload = {
        choosens: [choosenList],
        roommateId: choosenList[0].id,
        eventId,
        roomSize,
        status: "completed",
        enabled: true,
        idiom: "ptBr",
        sendEmail: false,
      };

      await createRoom(payload, token).then((res) => {
        // console.log("ok", res);
        if (res.message === "SENDED_TO_QUEUE") {
          setTotalSended(count++);
        }
        if (totalRows === ++index) {
          setBtnCancel(false);
        }
        if (count === totalRows || (count === 2 && totalRows === 1)) {
          setsuccess_dlg(true);
          // props.data[0].refetch();
          // props.data[1].refetch();
        }
      });
      // .catch((error) => console.log(error));
    }
  }
  ("Cannot read properties of undefined (reading 'toString')");
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Upload"}
          onConfirm={() => {
            updateTables();
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"O arquivo foi enviado com sucesso!"}
        </SweetAlert>
      ) : null}

      {modalErrors && (
        <ModalExcelRows
          toggle={() => setModalErrors(false)}
          rows={errors}
          columnNames={excelColumnsPtBr}
        />
      )}
      <ModalHeader toggle={toggle}>Importar Quartos</ModalHeader>
      <ModalBody className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <CardText className="mb-2 p-10">
              O arquivo a ser importado deve seguir este{" "}
              <a href={ModeloUpload} download="modelo_quartos.xlsx">
                Modelo
              </a>
              , não modifique seu cabeçalho.
            </CardText>

            <Form>
              {selectedFiles.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-1"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-4">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                          <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              <div className="dropzone-previews mt-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-1">
                        <Row className="align-items-center">
                          <Col xs="11">
                            <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                          </Col>
                          <Col xs="1">
                            <span
                              style={{
                                fontSize: "16px",
                                color: "red",
                                fontWeight: "700",
                              }}
                              className="mdi mdi-close"
                              onClick={() => setselectedFiles([])}
                            />
                          </Col>
                        </Row>
                        {importActivated.current && (
                          <Row className="align-items-center">
                            <Col>
                              <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Form>

            <div className="text-center mt-3">
              <p style={{ color: "red" }}>{imgError}</p>
              <button
                type="button"
                disabled={!btnSend}
                className="btn btn-primary waves-effect waves-light"
                onClick={() => uploadService()}
              >
                Enviar Quartos
              </button>
              <button
                type="button"
                className="btn btn-danger mx-1"
                disabled={!btnCancel}
                onClick={() => cancelImport()}
              >
                Cancelar
              </button>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}
