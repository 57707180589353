import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getAllVehicles(
  eventId,
  offset,
  limit,
  search,
  columnSearch = ""
) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/vehicle/${eventId}/event/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function createVehicle(data) {
  var config = {
    method: "post",
    url: `${urlBase}/vehicle`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function updateVehicle(data, id) {
  var config = {
    method: "put",
    url: `${urlBase}/vehicle/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteVehicle(id) {
  var config = {
    method: "delete",
    url: `${urlBase}/vehicle/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
export { createVehicle, deleteVehicle, getAllVehicles, updateVehicle };
