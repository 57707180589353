import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// select
import Select from "react-select";
//services
import { getParticipants } from "../../../utils/services/participants";
import { createTransfer } from "../../../utils/services/transfer";
// componentes
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import { useLocation } from "react-router-dom";

import { getAllVehicles } from "../../../utils/services/vehicles";

function NewTransfer(props) {
  const location = useLocation();
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de participantes
  const [participants, setParticipants] = useState([]);
  const [participantSelected, setParticipantSelected] = useState();
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [sourcerError, setSourceError] = useState("");
  const [destinyError, setDestinyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [selectVehicle, setSelectVehicle] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState();

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }
  console.log(vehicles);
  const submitForm = async (e) => {
    //tratamento de erros
    if (!participantSelected || participantSelected === "Selecione") {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");

    if (e.target[1].value === "") {
      setCompanyError("O campo Empresa é obrigatório");
      return;
    } else setCompanyError("");

    if (e.target[2].value === "") {
      setSourceError("O campo Origem é obrigatório");
      return;
    } else setSourceError("");

    if (e.target[3].value === "") {
      setDestinyError("O campo Destino é obrigatório");
      return;
    } else setDestinyError("");

    if (e.target[4].value === "") {
      setDateBoardingError("O campo Data embarque é obrigatório");
      return;
    } else setDateBoardingError("");

    if (e.target[5].value === "") {
      setTimeBoardingError("O campo Hora embarque é obrigatório");
      return;
    } else setTimeBoardingError("");

    // if (e.target[6].value === "") {
    //   setDateArrivalError("O campo Data chegada é obrigatório");
    //   return;
    // } else setDateArrivalError("");

    // if (e.target[7].value === "") {
    //   setTimeArrivalError("O campo Hora chegada é obrigatório");
    //   return;
    // } else setTimeArrivalError("");
    setIsloading(true);
    await createTransfer({
      event: props.idEvent,
      login: participantSelected,
      company: e.target[1].value,
      origin: e.target[2].value,
      destination: e.target[3].value,
      boardingdate: e.target[4].value,
      arrivaldate: e.target[6].value !== "" ? e.target[6].value : null,
      boardingtill: e.target[5].value,
      arrivaltime: e.target[7].value,
      meetingpoint: e.target[8].value,
      drivername: e.target[9].value,
      driverphone: e.target[10].value,
      extrainformation: e.target[11].value,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        setIsloading(false);
        setsuccess_dlg(true);
        // console.log(response);
      })
      .catch((error) => {
        setIsloading(false);
        // console.log("error", error);
      });
  };

  async function getAllParticipants() {
    await getParticipants({
      jwt: props.state.global_user_data.data.token,
      idEvent: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setParticipants(response.data.data);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }
  async function getAll() {
    getAllVehicles(location.state.idEvent, 1, 10, "", "").then((res) => {
      if (res.data.message === "FOUND") {
        setVehicles(res.data.findAll.data);
        // setCountData(res.data.findAll.count);
      }
    });
  }
  useEffect(() => {
    getAllParticipants();
  }, []);

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            toggle();
            // props.getAllTransfers();
            props.data[0].refetch();
            props.data[1].refetch();
            setsuccess_dlg(false);
          }}
        >
          {"Transfer criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Transfer</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          {checkProductExists("Gestão de Logística") && (
            <Row>
              <Col lg={12}>
                <Row>
                  <Col md={12}>
                    <div
                      className="form-check form-switch"
                      style={{ marginBottom: 10 }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkVehicle`}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectVehicle(true);
                            getAll();
                          } else setSelectVehicle(false);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`checkVehicle`}
                      >
                        Selecionar Veículo
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {selectVehicle ? (
            <Row>
              <Col lg={12}>
                <Select
                  placeholder={"Selecione um veículo..."}
                  onChange={(e) => {
                    setVehicleSelected(e.value);
                  }}
                  options={[
                    {
                      options: [
                        ...vehicles.map((e) => {
                          return { label: e.description, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: 150,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: 150,
                      overflowY: "auto",
                    }),
                  }}
                />
                {participantError && (
                  <a style={{ color: "red" }}>{participantError}</a>
                )}
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col lg={12}>
                  <Select
                    placeholder={"Selecione um participantes..."}
                    onChange={(e) => {
                      setParticipantSelected(e.value);
                    }}
                    options={[
                      {
                        options: [
                          ...participants.map((e) => {
                            return { label: e.nome, value: e.id };
                          }),
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                  {participantError && (
                    <a style={{ color: "red" }}>{participantError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Empresa*</label>
                    <input className="form-control" type="text" />
                  </div>
                  {companyError && (
                    <a style={{ color: "red" }}>{companyError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Origem*</label>
                    <input className="form-control" type="text" />
                  </div>
                  {sourcerError && (
                    <a style={{ color: "red" }}>{sourcerError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Destino*</label>
                    <input className="form-control" type="text" />
                  </div>
                  {destinyError && (
                    <a style={{ color: "red" }}>{destinyError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={6}>
                  <div>
                    <label className="form-label">Data embarque*</label>
                    <input
                      className="form-control"
                      type="date"
                      // defaultValue="2019-08-19T13:45:00"
                      id="example-datetime-local-input"
                    />
                    {dateBoardingError && (
                      <a style={{ color: "red" }}>{dateBoardingError}</a>
                    )}
                  </div>
                </Col>

                <Col lg={6}>
                  <div>
                    <label className="form-label">Hora embarque*</label>
                    <input
                      className="form-control"
                      type="time"
                      // defaultValue="2019-08-19T13:45:00"
                      id="example-datetime-local-input"
                    />
                  </div>
                  {timeBoardingError && (
                    <a style={{ color: "red" }}>{timeBoardingError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={6}>
                  <div>
                    <label className="form-label">Data chegada</label>
                    <input
                      className="form-control"
                      type="date"
                      // defaultValue="2019-08-19T13:45:00"
                      id="example-datetime-local-input"
                    />
                  </div>
                  {dateArrivalError && (
                    <a style={{ color: "red" }}>{dateArrivalError}</a>
                  )}
                </Col>

                <Col lg={6}>
                  <div>
                    <label className="form-label">Hora chegada</label>
                    <input
                      className="form-control"
                      type="time"
                      // defaultValue="2019-08-19T13:45:00"
                      id="example-datetime-local-input"
                    />
                  </div>
                  {timeArrivalError && (
                    <a style={{ color: "red" }}>{timeArrivalError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Ponto de encontro</label>
                    <input className="form-control" type="text" />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Nome do motorista</label>
                    <input className="form-control" type="text" />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Telefone do motorista</label>
                    <input className="form-control" type="text" />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Informações extras</label>
                    <input className="form-control" type="text" />
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row
            style={{
              width: "100%",
              margin: "20px auto 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewTransfer);
